import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card, Image } from "react-bootstrap";
import "./Managemant.scss";
import AxiosCall, { BASE_URL } from "../AxiosCall/AxiosCall";
import { StatesContext } from "../../context/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";
import "../Detailed/scss/Detailed.scss";

const Managemant = () => {
  const [activeKey, setActiveKey] = useState(null);
  const [tuzilma, setTuzilma] = useState([]);
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const { lang } = useContext(StatesContext);
  const { id } = useParams();
  const [rector, setRector] = useState([]);
  const [data, setData] = useState(null);

  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  const getContentNews = () => {
    AxiosCall("get", `/content/news2/${lang}/${id}`).then((data) => {
      setNews(data);
    });
    AxiosCall("get", `/content/${lang}/${id}`).then((data) => {
      setData(data);
    });
  };
  const getRectorData = () => {
    AxiosCall(
      "get",
      `/employees/get/${lang}?categoryId=6b867d19-e459-40f0-8aa3-c1ccc21c4522`
    ).then((data) => {
      setRector(data);
    });
  };
  const getStructures = () => {
    AxiosCall(
      "get",
      "/navbar_categories_2/structure/" +
        lang +
        "?categoryId=246f4d94-30e4-475a-bdb4-bd97f2bb0881"
    ).then((data) => {
      setTuzilma(data);
    });
  };
  useEffect(() => {
    getStructures();
    getContentNews();
    getRectorData();
  }, []);
  console.log(rector);

  return (
    <div className="management-container container">
      <div className="rector-container">
        <Card className="rector-card">
          <div className="rector-header">
            <Image
              src="https://via.placeholder.com/150"
              alt="Rector Image"
              className="rector-image"
            />
            <div className="rector-info">
              <h2 className="rector-title">
                {rector[0]?.employeeTranslations[0]?.degree}
              </h2>
              <h4 className="rector-name">{rector[0]?.fullName}</h4>
              <h6 className="rector-degree">
                {rector[0]?.employeeTranslations[0]?.description}
              </h6>
              <div className="rector-details">
                <p>
                  <strong>Office Hours:</strong>{" "}
                  {rector[0]?.employeeTranslations[0]?.workTime}
                </p>
                <p>
                  <strong>Phone:</strong> 8 (365) 221-27-07
                </p>
                <p>
                  <strong>Address:</strong>{" "}
                  {rector[0]?.employeeTranslations[0]?.location}
                </p>
                <p>
                  <strong>Email:</strong> {rector[0]?.email}
                </p>
                <p>
                  <strong>Fax:</strong> {rector[0]?.phoneNumber}
                </p>
              </div>
            </div>
          </div>
          <Accordion activeKey={activeKey} className="rector-accordion">
            {rector[0]?.employeeDynamicFields.map((item) => (
              <Card>
                <Accordion.Header onClick={() => toggleAccordion("0")}>
                  {item?.title}
                </Accordion.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>{item?.description}</Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Card>

        <div className="rector-duties-container">
          <Card className="duties-card">
            <Card.Body>
              <h2 className="rector-title">Rector</h2>
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: data?.body }}
              ></div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="box-r">
        <div className="cards-container">
          <div className="cards1-container">
            {lang === "uz" ? (
              <>
                <h6>Tuzilma</h6>
              </>
            ) : lang === "en" ? (
              <>
                <h6>Structure</h6>
              </>
            ) : (
              <>
                <h6>Структура</h6>
              </>
            )}
            {tuzilma.map((item, index) => {
              return <span key={index}>{item?.name}</span>;
            })}
          </div>
          <div className="cards2-container">
            <h6>
              {lang === "uz"
                ? "Yangiliklar"
                : lang === "en"
                ? "News"
                : "Новости"}
            </h6>
            <div className="cards">
              {news.map((item, index) => {
                const createdAt = new Date(item.created_at);
                const formattedDate = `${(createdAt.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}.${createdAt
                  .getDate()
                  .toString()
                  .padStart(2, "0")}.${createdAt.getFullYear()}`;

                return (
                  <div
                    key={index}
                    className="my-card1"
                    onClick={() => navigate(`/${item.id}`)}
                  >
                    <div className="textes">
                      <span className="time">{formattedDate}</span>
                      <span className="text">{item.title}</span>
                    </div>
                    <div className="image">
                      <img
                        src={
                          item.url != null
                            ? BASE_URL + item.url
                            : "https://archive.org/download/no-photo-available/no-photo-available.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Managemant;
