import React from 'react';
// import { Helmet } from 'react-helmet-async';
function DynamicMetaTags({ pageTitle, pageDescription }) {
    return (
        <div>
            {/* <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
            </Helmet> */}
        </div>
    );
}

export default DynamicMetaTags;
