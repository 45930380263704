import React from 'react';
import './scss/Loading.scss'
const Loading = () => {
    return (
        <div className="loader">
            <div className=" jimu-primary-loading"></div>
        </div>
    );
}
export default Loading;
